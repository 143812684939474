<script lang="ts" setup>
  import VInput from '~/components/ui/VInput.vue';
  import VButton from '~/components/ui/VButton.vue';
  import { checkPromocode } from '~/services/modules/subscriptions.service';
  import type { IPromocodeDetails } from '~/types/common';
  import { errorHandler } from '~/helpers/common';
  import { pluralize } from '~/helpers/processing';
  import useStatefulCookie from '~/composables/useStatefulCookies';

  const GiftModal = defineAsyncComponent(() => import('~/components/modals/subscription/ModalGift.vue'));
  const { onReveal: showGiftSubGivenModal } = createModal(GiftModal);

  const emit = defineEmits<{ (e: 'on-gift-success'): void }>();

  const promocodeCookie = useStatefulCookie<string | null>('promo_code');

  const loading = ref<boolean>(false);
  const error = ref<string>('');
  const modelValue = defineModel<IPromocodeDetails | null>();
  const promocode = ref<string>(modelValue.value?.name || '');
  const isTargetKgDomain = useState('isTargetKgDomain');
  const route = useRoute();
  const { fetchUser } = useAuthStore();

  const promocodeBenefits = computed<{ name: string; value: string }[]>(() => {
    const currency = ` ${modelValue.value?.currency?.sign ?? (isTargetKgDomain.value ? 'C' : '₽')}`;
    const benefits = [];
    if (modelValue.value?.discount_money || modelValue.value?.discount_procent) {
      const value = (modelValue.value?.discount_money ?? 0) / 100 || modelValue.value?.discount_procent;
      const sign = modelValue.value?.discount_money ? currency : '%';
      benefits.push({ name: 'Скидка', value: value + sign });
    }
    if (modelValue.value?.add_days)
      benefits.push({
        name: `${modelValue.value?.add_days}
         ${pluralize(modelValue.value?.add_days, ['дополнительный', 'дополнительных', 'дополнительных'])}
         ${pluralize(modelValue.value?.add_days, ['день', 'дня', 'дней'])}`,
        value: '',
      });
    if (modelValue.value?.free_films_count)
      benefits.push({
        name: `${modelValue.value?.free_films_count} ${pluralize(modelValue.value?.free_films_count, ['фильм', 'фильма', 'фильмов'])}`,
        value: '',
      });
    if (modelValue.value?.refund_amount)
      benefits.push({ name: 'Вернем после оплаты', value: modelValue.value?.refund_amount / 100 + currency });

    if (modelValue.value?.bonus_amount) {
      benefits.push({
        name: 'На баланс:',
        value: `+${modelValue.value.bonus_amount / 100 + currency}`,
      });
    }

    return benefits;
  });

  const applyPromocode = async () => {
    try {
      loading.value = true;
      error.value = '';
      const data = await checkPromocode(promocode.value);

      if (data.is_gift) {
        modelValue.value = null;
        showGiftSubGivenModal({
          id: data.subscription,
        });
        promocodeCookie.value = null;
        promocode.value = '';
        return await fetchUser();
      }

      modelValue.value = { ...data, name: promocode.value };
      promocodeCookie.value = promocode.value;
    } catch (e: any) {
      errorHandler({ message: e }, (message: string) => {
        error.value = message;
      });
      modelValue.value = null;
    } finally {
      loading.value = false;
    }
  };

  watch(
    () => promocodeCookie.value,
    () => {
      promocode.value = promocodeCookie.value || '';
      if (!promocodeCookie.value) {
        modelValue.value = null;
      }
    },
  );
  onMounted(async () => {
    if (promocodeCookie.value) {
      promocode.value = promocodeCookie.value;
      await applyPromocode();
      if (route.name === 'subscriptions' && !route.hash.includes('#subscription-promocode')) {
        document.getElementById('subscription-promocode')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  });
</script>

<template>
  <div class="subscription-promocode" id="subscription-promocode">
    <v-input
      name="promocode"
      class="subscription-promocode__input"
      :class="{ confirm: modelValue }"
      placeholder="Промокод"
      :error-message="error"
      v-model="promocode"
    />
    <v-button
      size="normal"
      @click="applyPromocode"
      :loading="loading"
      color="gradient"
      appearance="outline"
      :disabled="promocode === modelValue?.name"
    >
      Применить
    </v-button>
    <div v-if="promocodeBenefits.length" class="subscription-promocode__benefit-list">
      <span class="subscription-promocode__benefit" v-for="item in promocodeBenefits">
        {{ item.name }} {{ item.value }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .subscription-promocode {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
    width: 100%;
    padding: 24px;
    background: $main_black;
    border-radius: 32px;

    &__input {
      z-index: 1;
    }

    &__benefit-list {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }
    &__benefit {
      padding: 4px 12px;
      border-radius: 23px;
      background-color: $main_green_light;
      color: $main_black;
      font-size: 10px;
      font-weight: 600;
      line-height: 16px;
    }
    button {
      max-height: 48px;
    }
    &#subscription-promocode {
      scroll-margin-top: 180px;
    }
    @media (max-width: $retina) {
      grid-template-columns: 1fr;
      button {
        width: 100%;
      }
    }
  }
</style>
